import React from "react"
import Seo from "../../../components/seo"

import HeatRecovery from "./heatrecovery"


const HeatRecoveryIndex = () => (

  <>
    <Seo 
      title="Рекуперация тепла для технологических нужд и отопления"
      description="Повышение энергоэффективности предприятия и снижение затрат на энергию с помощью рекуперации тепла и отопления"
      keywords="рекуперация, энергоэффективность, ESG, рекуперация тепла, рекуперация отопления"
    />
    <HeatRecovery />
  </>
  
)

export default HeatRecoveryIndex